<template>
    <v-dialog v-model="dialog" persistent max-width="675px">
		<template v-slot:activator="{ on, attrs }">
			<v-btn depressed dark v-bind="attrs" v-on="on">
				Crear incidencia
				<v-icon  small class="ml-2">mdi-plus</v-icon>
			</v-btn>
		</template>
		<v-form ref="form" @submit.prevent="submit">
			<v-card>
                <v-card-title>
                    <span class="text-h5">Crear incidencia</span>
                    <v-spacer></v-spacer>
                    <v-btn @click="close" icon><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-card-subtitle>Detalles de incidencia</v-card-subtitle>
                <v-card-text>
					<v-row>
						<v-col md="6" class="pb-0"><v-text-field v-model="from_date" type="date" label="En/Desde" :rules="rules" hint="mm/dd/yyyy" prepend-inner-icon="mdi-calendar" class="mb-3 rounded-lg" outlined dense required persistent-hint></v-text-field></v-col>
						<v-col md="6" class="pb-0"><v-text-field v-model="to_date" type="date" label="Hasta (si aplica)" hint="mm/dd/yyyy" prepend-inner-icon="mdi-calendar" class="mb-3 rounded-lg" clearable outlined dense required persistent-hint></v-text-field></v-col>
						<v-col md="6" class="py-0">
							<v-autocomplete v-model="employee_number" :items="employees" item-text="name" item-value="employee_number" :rules="rules" class="rounded-lg" ref="employee_number" :disabled="loading" prepend-inner-icon="mdi-magnify" label="Empleado" outlined dense></v-autocomplete>
							<v-select v-model="incident_type_id" :items="incident_types" @change="incident_change" :rules="rules" item-value="id" item-text="description" label="Tipo de incidente" class="rounded-lg" outlined dense required></v-select>
							<v-select v-if="incident_type_id === 4" v-model="lunch_break_incident_type_id" :items="lunch_break_incident_types" :rules="rules" item-value="id" item-text="description" label="Tipo periodo de almuerzo" class="rounded-lg" outlined dense required></v-select>
						</v-col>
						<v-col md="6" class="py-0"><v-textarea v-model="comments" label="Comentarios" class="rounded-lg" rows="2" outlined dense required></v-textarea></v-col>
						<v-col md="6" class="py-0">

						</v-col>
						<v-col md="6" class="py-0"></v-col>
					</v-row>
				</v-card-text>
				<v-card-actions class="justify-end px-3 pb-5">
					<v-btn :disabled="loading" color="primary" type="submit" text>Someter</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import { get_current_date } from '@/assets/modules'

export default {
	name: 'CreateIncidents',
	data: () => ({
		dialog: false,
		employee_number: '',

		loading: false,

		from_date: null,
		to_date: null,
        comments: null,

        incident_type_id: null,
        lunch_break_incident_type_id: null,
        incident_types: [],
		lunch_break_incident_types: [],
        rules: [v => !!v || 'Campo es requerido'],
	}),
	mounted() {
		this.from_date = this.get_current_date()
		// this.$refs['employee_number'].$refs.input.select()
		this.get_incident_types()
	},
	methods: {
		get_current_date,
		get_incident_types() {
			this.loading = true
			this.$http.get('/admin/incident-types').then((resp) => {
				this.incident_types = resp.data.incident_types
				this.lunch_break_incident_types = resp.data.lunch_break_incident_types
			}).finally(() => {
				this.loading = false
			})
		},
		incident_change() {
			if (this.incident_type_id !== 4) this.lunch_break_incident_type_id = null
		}, 
		submit() {
            if (!this.$refs.form.validate()) return
			this.loading = true
			this.$http.post('/admin/incidents', this.args).then((resp) => {
                this.$root.$emit('refresh-incidents')
				this.close()
			}).finally(() => {
				this.loading = false
			})
		},
        close() {
            this.dialog = false
			this.$refs.form.reset()
        },
	},
	computed : {
		...mapGetters(['employees']),
		args() {
			return {
				employee_number: this.employee_number,
				from_date: this.from_date,
                to_date: this.to_date,
                incident_type_id: this.incident_type_id,
				lunch_break_incident_type_id: this.lunch_break_incident_type_id,
                comments: this.comments
			}
		}
    },
	watch: {
		dialog(v) {
			if (v) this.from_date = this.get_current_date()
		} 
	}
}
</script>
